
import React from 'react';
import './Footer.css';
import { FaFacebook, FaYoutube, FaTiktok } from 'react-icons/fa'; 

const Footer = () => {
  return (
    <>
      <div className="styled-footer">
        <p>&copy; 2024 Camp JakkalsFontein. All rights reserved.</p>
        <div className="social-icons">
          <a href="https://www.facebook.com/profile.php?id=61562243908238&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
          <a href="https://youtube.com/@campjakkalsfonteintulbaghs-d4f?si=nGoSV12oAy70e2Ll" target="_blank" rel="noopener noreferrer">
            <FaYoutube />
          </a>
          <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
            <FaTiktok />
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;

import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="styled-about-content">
      <span className="about-text">
        <p className="sentence-red-first">
          Welcome to our exclusive 3.8-hectare farm and campsite, where you and your group will enjoy complete privacy across the entire property, including a fully furnished barn and an additional 3-bedroom cottage.
        </p>
        <ul className="list-items">
          <li>
            We can provide up to 7-8 private rooms for couples, along with additional sleeper couches and bunk beds, ensuring everyone is comfortably accommodated.
          </li>
          <li>
            Your group will have exclusive access to all facilities, including a beautifully renovated barn ideal for events and gatherings. The property is perfect for weddings, meetings, or any function for 6 to 12 guests.
          </li>
          <li>
            For outdoor enthusiasts, we offer prime camping spots with well-maintained ablution facilities, including hot and cold showers.
          </li>
          <li>
            Guests can enjoy both indoor and outdoor braai (barbecue) areas, a spacious swimming pool, and a fully equipped kitchen, catering to up to 12 people.
          </li>
          <li>
            Experience a blend of rustic charm and luxury, with exclusive access to indoor spaces, the swimming pool, entertainment area, and camping grounds. If you prefer to set up your own camp or glamping site, we’re here to help.
          </li>
          <li>
            The Barnhouse and Cottage at Camp Jakkalsfontein are part of our Entire Farm offering, with all queen-sized beds fully outfitted, and bunk beds provided with mattresses and fitted sheets.
          </li>
        </ul>
      </span>
    </div>
  );
};

export default About;

import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';

const Contact = ({ isModalOpen, closeModal }) => {

  // required email inputs for emailjs data
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      'service_h71phpu',    // Service ID
      'template_scobuyk',   // Template ID
      formData,
      '95Fhx-o0j3iqug2KP'  // User ID
    ).then((response) => {
      alert('Email sent successfully!');
      setFormData({ user_name: '', user_email: '', subject: '', message: '' });
    }).catch((error) => {
      console.error('Failed to send email:', error);
    });
  };

  return (
    <>
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-button" onClick={closeModal}>&times;</span>
            <form onSubmit={handleSubmit}>
              <div className="form-field">
                <label htmlFor="user_email">Email:</label>
                <input
                  type="email"
                  id="user_email"
                  name="user_email"
                  className="input"
                  value={formData.user_email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-field">
                <label htmlFor="subject">Subject:</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  className="input"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-field">
                <label htmlFor="message">Message:</label>
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  className="textarea"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit" className="button">Send</button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Contact;

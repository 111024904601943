import React from 'react';
import './Home.css';
import image1 from '../../assets/pictures/veld.jpg';
import image2 from '../../assets/pictures/view.jpg';
import image3 from '../../assets/pictures/pinkflowers.jpg';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../../assets/pictures', false, /\.(png|jpe?g|svg)$/));

const Home = () => {
  return (
    <>
      <div className="home-container">
        <div className="styled-home-content">
          <h3 className="home-text">
            <span className="bold">Camp JakkalsFontein</span>
            <span className="rest">
              Camp Jakkalsfontein offers the ultimate glamorous camping and farm stay experience. Choose from roughing a bit camping style to glamping or settle for a luxurious barnhouse living experience.

              We cater for couples or groups of up to 20 persons for those special events/ occasions, team building, families, etc.

              Enjoy nature views of the surrounding Witzenberg, Winterhoek, and Obiqua Mountains.

              Partake in hiking, mountain biking, swimming, waterfalls, picnic, or simply enjoy the peaceful and quiet environment.

              Unlimited wifi, streaming and indulging in a cinema experience.
            </span>
          </h3>
          <div
            className='contact-button'
            onClick={(e) => {
              e.preventDefault();
              window.location.href = 'https://www.airbnb.co.za/rooms/1205743650943499456?c=.pi80.pkY29ob3N0aW5nL2NvaG9zdGluZ19pbnZpdGVfcmVtaW5kZXJfdG9faW52aXRlZQ%3D%3D&euid=9c2612f8-c453-2e5d-faf5-d2c361f1209e&source_impression_id=p3_1724580997_P3MrdARJGNobOSOa';
            }}
          > Book Now
          </div>
        </div>
      </div>
      <div className='rest-home'>
        <div className='camp-jk-summary'>
          <span className='heading'>Welcome to Camp JakkalsFontein</span>
          <span className='body'>
            Experience the beauty of nature with us! Book your adventure today and immerse yourself in the natural world like never before. Everyone is welcome to join us in reconnecting with the environment—let's explore, discover, and help preserve the wonders of our planet together.
          </span>
          <p className="body">
            Tulbagh is nestled at the northern tip of the stunning Tulbagh Valley, surrounded by breathtaking mountain ranges.
          </p>
        </div>
        <div className='more-camp-info'>
          <div className='camp-info-one'>
            <img src={image1} alt='veld' />
            <div className='image-summary'>To the West, you'll find the Obiqua Mountains, offering spectacular sunsets that paint the sky in hues of gold and crimson.</div>
          </div>
          <div className='camp-info-two'>
            <img src={image2} alt='view' />
            <div className='image-summary'>At the Northern end stands the majestic Winterhoek, towering above the valley with its imposing presence.</div>
          </div>
          <div className='camp-info-three'>
            <img src={image3} alt='pinkflowers' />
            <div className='image-summary'>To the East, the Witzenberg Mountains greet each day with the first light of dawn, where the sun rises to illuminate the valley.</div>
          </div>
        </div>
        <div className='carousel'>
          <div className='carousel-header'>
            <span className='gallary'>Our Gallery</span>
          </div>
          <div className="carousel-wrapper">
            <Carousel
              showThumbs={false}
              autoPlay
              infiniteLoop
              centerMode
              centerSlidePercentage={33.33}
              slideInnerMargin={10}
              showStatus={false}
              showIndicators={false}
            >
              {images.map((image, index) => (
                <div key={index} className="carousel-slide">
                  <img src={image} alt={`Image ${index + 1}`} />
                </div>
              ))}
            </Carousel>
          </div>

        </div>

      </div>
    </>
  );
};

export default Home;



import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import './Map.css';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: -33.3398990,
  lng: 19.1169840,
};

const Map = () => {

return (
  
  <div className="map-container">
    <div className='map-info'>
      <span className='map-header'>
        Find Our Location
      </span>
      <span className='map-body'>
        Away from the busy city life, come find us at...
      </span>
    </div>
    <div className='map'>
    <LoadScript googleMapsApiKey="AIzaSyCAO-FlkvPB-6XWRTm-wWnF1LzPPXCzvQs">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
    </div>
  </div>
);
};

export default Map

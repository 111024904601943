import React from 'react';
import './Services.css';

const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../../assets/icons', false, /\.(png|jpe?g|svg)$/));

const Services = () => {
  const descriptions = [
    "Quad Biking",
    "Braai Area",
    "Pitch a tent",
    "Cleaning service",
    "Cycling Trails",
    "Hiking Trails",
    "Home Theatre",
    "Kitchen",
    "Pet Friendly",  
    "Playground",
    "Cleaning Service",
    "Pool",
    "Beautiful Waterfall",
    "Running Trails",
    "More To Come",
  ];

  return (
    <div className="services-background">
      <div className="Services-header">
        <h1>
          Services
        </h1>
      <div className="logo-container">
        {images.map((image, index) => (
          <div key={index} className="logo-wrapper">
            <img src={image} alt={`Image-${index + 1}`} className="logos" />
            <span className="logo-text">{descriptions[index]}</span>
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};

export default Services;

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Banner.css';
import logo from '../../assets/logo/badgeonly.png';

const Banner = ({ openContactModal }) => {
  const location = useLocation();

  const isNotLandingPage = location.pathname != '/';

  return (
    <div className='banner-container'>
      <div className={`banner-text ${isNotLandingPage ? 'lavender-text' : ''}`}>
        Camp JakkalsFontein</div>
      <div className="nav-links">
        <Link to="/" className={isNotLandingPage ? 'lavender-link' : ''}>Home</Link>
        <Link to="/about" className={isNotLandingPage ? 'lavender-link' : ''}>About</Link>
        <Link to="/services" className={isNotLandingPage ? 'lavender-link' : ''}>Services</Link>
        <Link to="/map" className={isNotLandingPage ? 'lavender-link' : ''}>Map</Link>
        <Link onClick={openContactModal} className={isNotLandingPage ? 'lavender-link' : ''} style={{ cursor: 'pointer' }}>Contact</Link>
      </div>
      <div className="banner-icon-container">
        <img className="banner-icon" src={logo} alt="Logo" />
      </div>
    </div>
  );
};

export default Banner;
